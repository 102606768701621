import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

const Example = props => {
  return (
    <div>
      <div className="p-3 my-2 rounded">
        <Toast>
          <ToastHeader>What is new!</ToastHeader>
          <ToastBody>Updates about your building</ToastBody>
        </Toast>
      </div>
    </div>
  );
};

export default Example;
