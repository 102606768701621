import React, { useState, useEffect, useRef, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyNeighbors } from '../../store/actions/neighbors';
import MessageModal from './MessageModal';
import pi from '../../images/default-profile-image.jpg';
import { Link } from 'react-router-dom';
import { Filter, Neighbors } from './styles';
import { FaSearch } from 'react-icons/fa';
import { Alert } from 'reactstrap';

const NeighborList = props => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const currentUser = useSelector(state => state.currentUser.user);
  const myNeighbors = useSelector(state => state.myNeighbors);
  const errors = useSelector(state => state.errors);
  useEffect(() => {
    dispatch(fetchMyNeighbors(currentUser.address));
  }, []);
  const handleFindPerson = () => {
    setQuery();
  };

  return (
    <>
      <Filter>
        <div>
          <Alert color="primary">
            <h4 className="alert-heading">COVID-19: Neighbors</h4>
            <hr />
            <p className="mb-0">
              Please follow local guidelines about physical distancing and
              staying home.
            </p>
          </Alert>
        </div>
        <h2>Neighbors</h2>
        <p>Start a conversation with your neighbor and get to know them</p>
        {errors.message && (
          <div className="alert alert-danger">{errors.message}</div>
        )}

        <form>
          <div>
            <FaSearch />
            <input
              type="text"
              placeholder="Search by name"
              // ref={input => (this.search = input)}
              onChange={handleFindPerson}
            />
          </div>
        </form>
      </Filter>
      <Neighbors>
        {errors.message && (
          <div className="alert alert-danger">{errors.message}</div>
        )}
        {myNeighbors.tenants
          .filter(user => {
            if (query === '') {
              return true;
            } else {
              const name = (
                user.firstName +
                ' ' +
                user.lastName
              ).toLocaleLowerCase();
              return name.indexOf(query) > -1;
            }
          })
          .map(
            user =>
              currentUser.id !== user._id && (
                <li key={user._id}>
                  <img src={user.image || pi} alt={user.email} />
                  <Link to={{ pathname: `/profile/${user._id}` }}>
                    {user.firstName} {user.lastName}
                  </Link>
                  <MessageModal
                    audienceId={user._id}
                    currentUserId={currentUser.id}
                    historyRoute="neighbors"
                    historyState="Your message is successfully sent"
                  />
                </li>
              ),
          )}
      </Neighbors>
    </>
  );
};

export default NeighborList;
